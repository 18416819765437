'use strict';

angular.module('windmanagerApp')
  .config(function($stateProvider) {
    $stateProvider
      .state('activity-log', {
        url: '/activity-log',
        template: '<activity-log></activity-log>',
        authenticate: true,
        data: {
          bodyClass: ''
        }
      });
  });
